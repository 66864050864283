import { SET_CURRENT_VOICE, SET_PLAYLIST, SET_PLAYING_STATUS, TOGGLE_VISIBLE_AUDIO_PLAYER } from './types'

const DEFAULT_STATE = { list: [], currentVoiceId: null, isPlaying: false, isVisible: false }

export function audioPlayer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PLAYLIST: {
      return { ...state, list: action.payload }
    }

    case SET_CURRENT_VOICE: {
      const index = state.list.findIndex(elem => elem.id === action.payload)
      if (index !== -1) {
        return { ...state, currentVoiceId: state.list[index].id }
      }
      return state
    }
    case SET_PLAYING_STATUS: {
			return {...state, isPlaying: action.payload}
		}
		case TOGGLE_VISIBLE_AUDIO_PLAYER : {
			return {...state, isVisible: action.payload}
		}
    default: {
      return state
    }
  }
}
