import axios from 'axios'
import { apiSettings } from '../utils/constants'

class VoiceDescrApi {
  getVoiceDescriptions = () => {
    return axios.get(apiSettings.voiceDescriptions)
  }
}

const voiceDescrApi = new VoiceDescrApi()

export default voiceDescrApi
