import axios from 'axios'
import { apiSettings } from '../utils/constants'

class WorkApi {
  getWorks = () => {
    return axios.get(apiSettings.workUrl)
  }
}

const workApi = new WorkApi()

export default workApi
