import { SET_REVIEWS, SET_HOME } from './types'
import reviewsApi from '../../services/reviews.service'
import homeApi from '../../services/home.service'

export const setReviews = payload => {
  return { type: SET_REVIEWS, payload }
}

export const setHome = payload => {
  return { type: SET_HOME, payload }
}

export const getHomeList = () => {
  return dispatch => {
    return homeApi
      .getHome()
      .then(data => {
        dispatch(setHome(data.data))
      })
      .catch(err => {
        console.error(err)
      })
  }
}

export const getReviewsList = () => {
  return dispatch => {
    return reviewsApi
      .getReviews()
      .then(data => {
        dispatch(setReviews(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}
