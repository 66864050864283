import axios from 'axios'
import { apiSettings } from '../utils/constants'

class ReviewsApi {
  getReviews = () => {
    return axios.get(apiSettings.reviewsUrl)
  }
}

const reviewsApi = new ReviewsApi()

export default reviewsApi
