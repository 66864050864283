import { SET_VOICES } from './types'
import voiceApi from '../../services/voice.service'

export const setVoices = payload => {
  return { type: SET_VOICES, payload }
}

export const getVoicesList = () => {
  return dispatch => {
    return voiceApi
      .getVoices()
      .then(data => {
        dispatch(setVoices(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}
