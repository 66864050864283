import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from './store'
import Main from './components/Main'
import ApiService from './services/api.service'
// import ReactGA from 'react-ga'
import firebase from 'firebase/app'
import { firebaseConfig } from './utils/firebaseConfig'
import TagManager from 'react-gtm-module'
// import 'firebase/firestore'
React.lazy(() => import('firebase/firestore'))

// const clutchScript = 'https://widget.clutch.co/static/js/widget.js'

// ReactGA.initialize('UA-167229283-1')

firebase.initializeApp(firebaseConfig)


 
const tagManagerArgs = {
    gtmId: 'GTM-T2WBLG4',
}
 
TagManager.initialize(tagManagerArgs)

export const App = () => {
  useEffect(() => {
    ApiService.configureDefaultApiClient()
	}, [])

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search)
  // }, [])
	// useLoadScript(clutchScript, 'clutchId')
	
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  )
}
