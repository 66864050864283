import axios from 'axios'
import { apiSettings } from '../utils/constants'

class VoiceApi {
  getVoices = () => {
    return axios.get(apiSettings.voicesUrl)
  }
}

const voiceApi = new VoiceApi()

export default voiceApi
