import axios from 'axios'
import { apiSettings } from '../utils/constants'

class ServiceDetailsApi {
  getServiceDetails = serviceType => {
    return axios.get(`${apiSettings.serviceDetailUrl}/${serviceType}`)
  }
}

const serviceDetails = new ServiceDetailsApi()

export default serviceDetails
