import { app } from './app/reducer'
import { voices } from './voices/reducer'
import { pricing } from './pricing/reducer'
import { voiceDescriptions } from './voiceDescriptions/reducer'
import { home } from './home/reducer'
import { work } from './work/reducer'
import { chronometers } from './chronometers/reducer'
import { about } from './about/reducer'
import { contacts } from './contacts/reducer'
import { audioPlayer } from './audioPlayer/reducer'
import { serviceDetails } from './serviceDetails/reducer'
import { wishlist } from './wishlist/reducer'
import { cases } from './cases/reducer'
import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
  app,
  voices,
  pricing,
  voiceDescriptions,
  home,
  work,
  chronometers,
  about,
  contacts,
  audioPlayer,
  serviceDetails,
	wishlist,
	cases,
})
