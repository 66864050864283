import { SET_CONTACTS, SET_STATUS_MESSAGE, SET_LODING_STATUS, CLEAR_LODING_STATUS } from './types'

const DEFAULT_STATE = {
  loadingStatus: false,
  statusMessage: '',
}

export function contacts(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CONTACTS: {
      return { ...action.payload }
    }

    case SET_STATUS_MESSAGE: {
      return { ...state, statusMessage: action.payload }
    }

    case SET_LODING_STATUS: {
      return { ...state, loadingStatus: true }
    }

    case CLEAR_LODING_STATUS: {
      return { ...state, loadingStatus: false }
    }

    default: {
      return state
    }
  }
}
