import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'
import { supportedLangs } from './utils/constants'

const customLangDetector = {
  name: 'mylang-detector',
  lookup() {
    const lng = window.location.pathname.split('/')[1]
    const language = lng && supportedLangs[lng] ? lng : 'en'
    return language
  },
}
const lngDetector = new LanguageDetector()
lngDetector.addDetector(customLangDetector)

i18n
  .use(Backend)
  .use(lngDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    detection: {
			order: ['mylang-detector'],
			caches: [],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  })

export default i18n
