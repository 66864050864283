import { SET_VOICES_DESCR } from './types'
import voiceDescriptions from '../../services/voiceDescriptions.service'
import * as actions from '../actions'

export const setVoicesDescr = payload => {
  return { type: SET_VOICES_DESCR, payload }
}

export const getVoicesDescr = () => {
  return (dispatch, getState) => {
    const state = getState()

    return voiceDescriptions
      .getVoiceDescriptions()
      .then(data => {
        if (state.voices.length > 0) {
          dispatch(setVoicesDescr(data.data))
        } else {
          dispatch(actions.getVoicesList())
          dispatch(setVoicesDescr(data.data))
        }
      })

      .catch(err => {
        console.error(err)
      })
  }
}
