export const pages = {
  MAIN: '',
  ALL_LANGUAGES: 'all-languages',
  BLOG: 'blog',
  BLOG_NEWS: 'blog/:news',
  ABOUT: 'about',
  VOICES: 'voices',
  VOICES_DETAIL: 'voices/:lang',
  WORK: 'portfolio',
  SHOWREELS: 'portfolio/showreels',
  CASES: 'portfolio/cases',
  PRICING: 'prices',
  REVIEWS: 'reviews',
  CHRONOMETER: 'chronometer',
  CONTACTS: 'contacts',
  PAYMENTS: 'payments',
  SERVICES: 'services',
	SERVICES_TYPE: 'services/:service',
  SERVICE_DETAIL: 'services/:service/:typeService',
}

export const Routes = {
  MAIN: `/${pages.MAIN}`,
  ABOUT: `/${pages.ABOUT}`,
  VOICES: `/${pages.VOICES}`,
  VOICES_DETAIL: `/${pages.VOICES_DETAIL}`,
  WORK: `/${pages.WORK}`,
  SHOWREELS: `/${pages.SHOWREELS}`,
  CASES: `/${pages.CASES}`,
  PAYMENTS: `/${pages.PAYMENTS}`,
  PRICING: `/${pages.PRICING}`,
  REVIEWS: `/${pages.REVIEWS}`,
  CHRONOMETER: `/${pages.CHRONOMETER}`,
  CONTACTS: `/${pages.CONTACTS}`,
  SERVICES: `/${pages.SERVICES}`,
	SERVICES_TYPE: `/${pages.SERVICES_TYPE}`,
  SERVICE_DETAIL: `/${pages.SERVICE_DETAIL}`,
  ALL_LANGUAGES: `/${pages.ALL_LANGUAGES}`,
  BLOG: `/${pages.BLOG}`,
  BLOG_NEWS: `/${pages.BLOG_NEWS}`,
}

export const chronoParams = {
  slow: 11,
  normal: 12,
  fast: 13,
  symbolsOnList: 2100,
}

export const options = [
  {
    name: 'Language',
    values: [
      'English',
      'French',
      'Arabic',
      'Spanish',
      'Portuguese',
      'German',
      'Russian',
      'Italian',
      'Chinese',
      'Japanese',
    ],
  },
  { name: 'Gender', values: ['Female', 'Male'] },
  {
    name: 'Age',
    values: ['Young', 'Middle aged', 'Adult'],
  },
]

export const btnDescription = {
  ORDER_VOICE: 'Order Voice',
  MORE: 'More',
  ALL_REVIEWS: 'All Reviews',
}

export const waveTypes = {
  SHARP_WAVE: 'sharp-wave',
  SHARP_WAVE_HIGH_DEF: 'sharp-wave@2x',
  SMOOTH_WAVE: 'smooth-wave',
}

export const countries = [
  'English',
  'French',
  'Arabic',
  'Spanish',
  'Portuguese',
  'German',
  'Russian',
  'Italian',
  'Chinese',
  'Japanese',
]

export const sponsors = [
  'Almay',
  'Fabbian',
  'Knoll',
  'Lalique',
  'Ryvita',
  'Esso',
  'Canvas',
  'Aquafresh',
  'Biostar',
  'Cash',
]

export const resolutions = {
  mobile: 320,
  tablet: 768,
  small_desktop: 1023,
  desktop: 1366,
  fullHD: 1920,
}

export const currentResolution = window.innerWidth

const apiUrl = 'https://us-central1-ummediaby-cc4fc.cloudfunctions.net/api'
// const apiUrl = 'http://localhost:5001/ummediaby-cc4fc/us-central1/api'

export const apiSettings = {
  voicesUrl: `${apiUrl}/getVoices`,
  chronometersUrl: `${apiUrl}/getChronometers`,
  pricingUrl: `${apiUrl}/getPricing`,
  voiceDescriptions: `${apiUrl}/getVoiceDescriptions`,
  workUrl: `${apiUrl}/getWork`,
  reviewsUrl: `${apiUrl}/getReviews`,
  aboutUrl: `${apiUrl}/getAbout`,
  contactsUrl: `${apiUrl}/getContacts`,
  createFeedBackUrl: `${apiUrl}/createFeedback`,
  homeUrl: `${apiUrl}/getHome`,
	serviceDetailUrl: `${apiUrl}/serviceDetails`,
	casesUrl: `${apiUrl}/cases`,
}

export const supportedLangs = {
  en: 'EN',
  ru: 'RU',
  de: 'DE',
}

export const constants = {
  langKey: 'umvoices-lang',
}

export const SERVICES = {
  VIDEO_LOCALIZATION: {
    PREPRODUCTION: {
      label: 'preproduction',
      value: 'preproduction',
      items: [
        { label: 'translating', value: 'translating' },
        { label: 'proofreading', value: 'proofreading' },
        { label: 'graphic-elements-replacing', value: 'graphic-elements-replacing' },
        { label: 'length-adaptation', value: 'length-adaptation' },
      ],
    },
    VIDEO_NARRATION: {
      label: 'video narration',
      value: 'video narration',
      items: [
        { label: 'e-learning', value: 'e-learning' },
        { label: 'presentations', value: 'presentations' },
        { label: 'documentaries', value: 'documentaries' },
        { label: 'advertising', value: 'advertising' },
        { label: 'trailers', value: 'trailers' },
      ],
    },
    POSTPRODUCTION: {
      label: 'postproduction',
      value: 'postproduction',
      items: [
        { label: 'cutting', value: 'cutting' },
        { label: 'dubbing', value: 'dubbing' },
        { label: 'subtitling', value: 'subtitling' },
      ],
    },
  },
  VOICE_OVER: {
    VOICE_OVER: {
      items: [
        { label: 'gaming', value: 'gaming' },
        { label: 'mobile-apps', value: 'mobile-apps' },
        { label: 'audioguides', value: 'audioguides' },
        { label: 'audiobooks', value: 'audiobooks' },
        { label: 'navigations', value: 'navigations' },
        { label: 'radio-spots', value: 'radio-spots' },
        { label: 'telephone-messages', value: 'telephone-messages' },
				{ label: 'tts-projects', value: 'tts-projects' },
      ],
    },
  },
}

export const SERVICES_TABS = {
  VIDEO_LOCALIZATION: {
    label: 'video-localization',
    value: 'video-localization',
  },
  VOICE_OVER: {
    label: 'voice-over',
    value: 'voice-over',
  },
}

export const EXCLUDED_DE_VOICES = [
  'Chinese Cantonese',
  'Dutch',
  'Canadian English',
  'Flemish',
  'Mexican Spanish',
  'Dari',
  'Amazigh',
  'Filipino',
  'Tagalog',
  'Australian English',
  'Amharic',
  'Bosnian',
  'Slovak',
  'Slovenian',
  'Tigrinya',
  'Moldovan',
  'Mongolian',
  'Albanian',
  'Catalan',
  'Malay',
  'Oromo',
  'Korean',
  'Uzbek',
  'Danish',
  'Pashto',
  'Indonesian',
  'Irish',
  'Khmer',
  'Tajik',
  'Tamil',
  'Wolof',
  'Iraqi',
  'Belarusian',
  'New Zealand English',
  'Macedonian',
  'Bengali',
  'Kyrgyz',
  'Swahili',
  'Burmese',
  'Kannada',
  'Zulu',
  'Moroccan Arabic',
  'Urdu',
  'Azeri',
  'Somali',
]