import axios from 'axios'
import { apiSettings } from '../utils/constants'

class CasesApi {
  getCases = () => {
    return axios.get(apiSettings.casesUrl)
  }
}

const casesApi = new CasesApi()

export default casesApi
