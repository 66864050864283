import { SET_CHRONOMETERS } from './types'

const DEFAULT_STATE = {
  texts: [],
}

export function chronometers(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CHRONOMETERS: {
      return action.payload
		}
		
    default: {
      return state
    }
  }
}
