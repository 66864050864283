import axios from 'axios'
import store from '../store'
import { setError, clearloadingStatus, clearError, setloadingStatus } from '../store/actions'

class ApiService {
  static configureDefaultApiClient = () => {
    // Set auth token for all requests
    axios.interceptors.request.use(function(config) {
      if (config.method !== 'post') {
        store.dispatch(setloadingStatus())
      }
      return config
    })

    // Configure interceptor action for the 401 response
    axios.interceptors.response.use(
      response => {
        store.dispatch(clearloadingStatus())
        store.dispatch(clearError())
        return response
      },
      error => {
        store.dispatch(clearloadingStatus())
        store.dispatch(setError(error.message))
        return Promise.reject(error.response)
      }
    )
  }
}

export default ApiService
