import { createStore, applyMiddleware, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history/'
import thunk from 'redux-thunk'
import { rootReducer } from './rootReducer'
import ym from 'react-yandex-metrika'

const history = createBrowserHistory()

history.listen(location => {
  ym('hit', location.pathname)
})

export function configureStore() {
  return createStore(rootReducer, undefined, compose(composeWithDevTools(applyMiddleware(thunk))))
}

const store = configureStore()
export default store
