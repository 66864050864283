import { SET_SERVICE_DETAILS } from './types'
import serviceDetails from '../../services/serviceDetails.service'

export const setServiceDetails = payload => {
  return { type: SET_SERVICE_DETAILS, payload }
}

export const getServiceDetails = serviceType => {
  return dispatch => {
    return serviceDetails
      .getServiceDetails(serviceType)
      .then(data => {
        dispatch(setServiceDetails(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}
