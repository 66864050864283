import {
  SET_CURRENT_VOICE,
  SET_PLAYLIST,
  SET_PLAYING_STATUS,
  TOGGLE_VISIBLE_AUDIO_PLAYER,
} from './types'

export const setPlayList = payload => {
  return { type: SET_PLAYLIST, payload }
}

export const setPlayingVoiceId = payload => {
  return { type: SET_CURRENT_VOICE, payload }
}

export const setPlayingStatus = payload => {
  return { type: SET_PLAYING_STATUS, payload }
}

export const toggleVisibleAudioPlayer = payload => {
  return {
    type: TOGGLE_VISIBLE_AUDIO_PLAYER,
    payload,
  }
}
