import React from 'react'
import { translate } from 'react-i18next'
import CookieConsent from 'react-cookie-consent'


const styles = () => {
  return (
    {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, 0)',
      backgroundColor: '#2B373B',
      fontFamily: 'Montserrat-medium',
      fontSize: '12px',
      width: 'fit-content',
    }
  )
  
}

const contentStyles = () => {
  return (
    {
      flex: '',
    }
  )
}

const buttonStyle = {
  color: '#ffffff',
  fontFamily: 'Montserrat-medium',
  fontSize: '12px',
	backgroundColor: '#51B5AE',
}

const CookieMessage = ({t}) =>  {
  return (
<CookieConsent
    location='none'
    buttonText={t('general.got it')}
    cookieName='umvoices'
    style={styles()}
    contentStyle={contentStyles()}
    buttonStyle={buttonStyle}
    expires={150}
  >
    {t('general.cookie')}
  </CookieConsent>
  )
}
  
  


export default translate('translations')(CookieMessage)
