import { SET_PRICING } from './types'
import pricingApi from '../../services/pricing.service'

export const setPricing = payload => {
  return { type: SET_PRICING, payload }
}

export const getPricing = () => {
  return dispatch => {
    return pricingApi
      .getPricing()
      .then(data => {
        dispatch(setPricing(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}