import axios from 'axios'
import { apiSettings } from '../utils/constants'

class PricingApi {
  getPricing = () => {
    return axios.get(apiSettings.pricingUrl)
  }
}

const pricingApi = new PricingApi()

export default pricingApi
