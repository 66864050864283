import { SET_CHRONOMETERS } from './types'
import chronoApi from '../../services/chronometers.service'

export const setChromoneters = payload => {
  return { type: SET_CHRONOMETERS, payload }
}

export const getChronometers = () => {
  return dispatch => {
    return chronoApi
      .getChronometers()
      .then(data => {
        dispatch(setChromoneters(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}