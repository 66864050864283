import React, { Component, Suspense } from 'react'
import { translate } from 'react-i18next'
import { mapDispatchToProps } from '../../helpers/redux.helper'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Modal from 'antd/lib/modal'
import message from 'antd/lib/message'
import { isMoreThenDisplayView } from '../../helpers/device.helper'
import { Routes as AppRoutes } from '../../utils/constants'
import PrivacyPolicy from '../PrivacyPolicy'
import PublicOffer from '../PublicOffer'
import Loader from '../Loader'
import './index.sass'

const FormComponent = React.lazy(() => import('../FormComponent'))

const mobileStyle = { top: 10, height: '98%' }
const desktopStyle = {
  top: 20,
  height: '98%',
  right: 20,
  margin: 0,
  marginLeft: 'auto',
  width: '80%',
}

const MODAL_TYPES = {
  publicOffer: 'publicOffer',
  privacyPolicy: 'privacyPolicy',
}

class OrderVoiceModal extends Component {
  componentDidUpdate = () => {
    if (
      this.props.statusMessage &&
      this.props.location.pathname !== `/${this.props.lng}${AppRoutes.CONTACTS}`
    ) {
      this.showStatusMessage()
    }
  }

  showStatusMessage = () => {
    const {
      statusMessage,
      actions: { setStatusMessage },
    } = this.props
    message.info(statusMessage)
    setStatusMessage('')
  }

  renderModalContent = () => {
    const {
      actions: {
        createFeedBack,
        setChoosenVoice,
        setContactLoadingStatus,
        clearContactLoadingStatus,
        setStatusMessage,
      },
      loadingStatus,
      choosenVoice,
      modalType,
    } = this.props

    switch (modalType) {
      case 'privacyPolicy': {
        return <PrivacyPolicy />
      }
      case 'publicOffer': {
        return <PublicOffer />
      }
      default: {
        return (
          <FormComponent
            choosenVoice={choosenVoice}
            createFeedBack={createFeedBack}
            loadingStatus={loadingStatus}
            setChoosenVoice={setChoosenVoice}
            setContactLoadingStatus={setContactLoadingStatus}
            clearContactLoadingStatus={clearContactLoadingStatus}
            setStatusMessage={setStatusMessage}
          />
        )
      }
    }
  }

  render = () => {
    const {
      actions: { changeModalVisibility, setChoosenVoice, setModalType },
      modalVisibility,
      modalType,
      t,
    } = this.props
    const title = MODAL_TYPES[modalType]
      ? t(`general.${MODAL_TYPES[modalType]}`)
      : t('general.order voice')

    return (
			<Suspense fallback={<Loader />}>
      <Modal
        title={title}
        visible={modalVisibility}
        onOk={() => {
          changeModalVisibility(false)
          setModalType('form')
        }}
        onCancel={() => {
          changeModalVisibility(false)
          setModalType('form')
          setChoosenVoice('')
        }}
        footer={null}
        wrapClassName={
          modalType === 'privacyPolicy' || modalType === 'publicOffer'
            ? 'order-modal privacy-modal'
            : 'order-modal'
        }
        style={isMoreThenDisplayView() ? desktopStyle : mobileStyle}
        bodyStyle={{ height: '100%' }}
        width={isMoreThenDisplayView() ? '80%' : '100%'}
      >
        {this.renderModalContent()}
      </Modal>
			</Suspense>
    )
  }
}

const mapStateToProps = state => ({
  modalVisibility: state.app.modalVisibility,
  loadingStatus: state.contacts.loadingStatus,
  statusMessage: state.contacts.statusMessage,
  choosenVoice: state.app.choosenVoice,
  modalType: state.app.modalType,
})

export default translate('translations')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(OrderVoiceModal))
)
