import { SET_REVIEWS, SET_HOME } from './types'

const DEFAULT_STATE = {}

export function home(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_HOME: {
      return { ...state, home: action.payload }
    }
    case SET_REVIEWS: {
      return { ...state, reviews: action.payload }
    }
    default: {
      return state
    }
  }
}
