import { SET_WORKS } from './types'
import workApi from '../../services/work.service'


export const setWorks = payload => {
  return { type: SET_WORKS, payload }
}

export const getWorksList = () => {
  return dispatch => {
    return workApi
      .getWorks()
      .then(data => {
        dispatch(setWorks(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}
