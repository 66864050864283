import axios from 'axios'
import { apiSettings } from '../utils/constants'

class ChronoApi {
  getChronometers = () => {
    return axios.get(apiSettings.chronometersUrl)
  }
}

const chronoApi = new ChronoApi()

export default chronoApi
