import React from 'react'
import DrawerNavigation from '../Drawer'
import { translate } from 'react-i18next'
import { resolutions } from '../../utils/constants'
import Navigation from '../Navigation'
import { Router, Switch, Route } from 'react-router-dom'
import OrderVoiceModal from '../OrderVoiceModal'
import Routes from '../../router'
import CookieMessage from '../CookieMessage'
import history from '../../history'
import { useWindowSize } from '../../hooks/useWindowSize'
import './index.sass'

const Main = ({ lng }) => {
  const { width } = useWindowSize()
  const visibleDrawer = width <= resolutions.small_desktop
  return (
    <div className="main">
      <div className="main-wrapper">
        <Router history={history}>
          {visibleDrawer ?
            <div style={{ display: visibleDrawer ? 'block' : 'none' }}>
              <DrawerNavigation visible={visibleDrawer} lng={lng} />
            </div> :
            <div className='navigation-wrapper' style={{ display: !visibleDrawer ? 'block' : 'none' }}>
              <Navigation />
            </div>
          }
          <Switch>
            <Route
              path={lng === 'en' ? '' : '/:locale'}
              render={props => <Routes {...props} lng={lng} />}
            />
          </Switch>
          <OrderVoiceModal />
        </Router>
        <CookieMessage />
      </div>
    </div>
  )
}

export default translate('translations')(Main)
