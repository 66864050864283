import { SET_SERVICE_DETAILS } from './types'

const DEFAULT_STATE = {}

export function serviceDetails(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_SERVICE_DETAILS: {
      return { ...state, [action.payload.id]: { ...action.payload } }
    }

    default: {
      return state
    }
  }
}
