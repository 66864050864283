import { SET_CASES } from './types'
import casesApi from '../../services/cases.service'


export const setCases = payload => {
  return { type: SET_CASES, payload }
}

export const getCases = () => {
  return dispatch => {
    return casesApi
      .getCases()
      .then(data => {
        dispatch(setCases(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}