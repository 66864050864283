import React from 'react'
import { render } from 'react-dom'
import { App } from './App'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import * as serviceWorker from './serviceWorker'
import './index.sass'
import { YMInitializer } from 'react-yandex-metrika'


const rootElement = document.getElementById("root")

  // render(
  // <I18nextProvider i18n={i18n}>
  //   <YMInitializer 
  //   accounts={[56509306]} 
  //   options={{
  //     webvisor: true, 
  //     clickmap: true,
  //     trackLinks: true,
  //     accurateTrackBounce: true
  //   }}/>
  //   <App />
  // </I18nextProvider>, rootElement)


  render(<I18nextProvider i18n={i18n}>
    <YMInitializer 
    accounts={[56509306]} 
    options={{
      webvisor: true, 
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
    }}
    version="2"/>
    <App />
  </I18nextProvider>, rootElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
