import { SET_PRICING } from './types'

const DEFAULT_STATE = {}

export function pricing(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PRICING: {
      return { ...action.payload }
    }

    default: {
      return state
    }
  }
}
