import { SET_WORKS } from './types'

const DEFAULT_STATE = []

export function work(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_WORKS: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
