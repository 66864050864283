import axios from 'axios'
import { apiSettings } from '../utils/constants'

class AboutApi {
  getAbout = () => {
    return axios.get(apiSettings.aboutUrl)
  }
}

const aboutApi = new AboutApi()

export default aboutApi
