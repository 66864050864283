import { SET_ABOUT } from './types'
import aboutApi from '../../services/about.service'


export const setAbout = payload => {
  return { type: SET_ABOUT, payload }
}

export const getAboutList = () => {
  return dispatch => {
    return aboutApi
      .getAbout()
      .then(data => {
        dispatch(setAbout(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}