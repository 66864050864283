import React from 'react'
import { translate } from 'react-i18next'
import Select from 'antd/lib/select'
import { supportedLangs, constants } from '../../utils/constants'
import storage from '../../services/storage/storage.service'
import { withRouter } from 'react-router-dom'
import './index.sass'

const getUrl = (lng, pathname) => {
  const [_, lang, ...rest] = pathname.split('/')
  const isLangInUrl = lang && supportedLangs[lang]

  let url
  if (isLangInUrl) {
    if (lng === 'en') {
      url = [_, ...rest].join('/')
    } else {
      url = [_, lng, ...rest].join('/')
    }
  } else {
    url = `/${lng}${pathname}`
  }

  return url
}


const { Option } = Select

const LangSelector = ({ i18n, history }) => {
  const changeLang = lng => {
    let pathname = history.location.pathname
    
    if (pathname[pathname.length-1]==='/') {
      pathname = pathname.slice(0,pathname[pathname.length-1])
    }
    const url = getUrl(lng, pathname)
    i18n.changeLanguage(lng)
    storage.setData(constants.langKey, lng)
    history.push(url)
  }

  return (
    <div className="lang-selector-wrapper">
      <Select
        defaultValue={i18n.language}
        onChange={changeLang}
        dropdownClassName="lang-selector-dropdown"
      >
        {Object.keys(supportedLangs).map((lang, ind) => (
          <Option key={ind} value={lang}>
            {supportedLangs[lang]}
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default translate('translations')(withRouter(LangSelector))
