import axios from 'axios'
import { apiSettings } from '../utils/constants'

class HomeApi {
  getHome = () => {
    return axios.get(apiSettings.homeUrl)
  }
}

const homeApi = new HomeApi()

export default homeApi
