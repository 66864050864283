import {
  LOADED,
  CHANGE_MODAL_VISIBILITY,
  LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SET_CHOOSEN_VOICE,
  SET_MODAL_TYPE,
} from './types'

const DEFAULT_STATE = {
  loading: false,
  modalVisibility: false,
  error: '',
  choosenVoice: '',
  modalType: 'form',
}

export function app(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      }
    }

    case LOADED: {
      return {
        ...state,
        loading: false,
      }
    }

    case SET_ERROR: {
      const { payload } = action
      return {
        ...state,
        error: payload,
      }
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        error: '',
      }
    }

    case CHANGE_MODAL_VISIBILITY: {
      return {
        ...state,
        modalVisibility: action.payload,
      }
    }

    case SET_CHOOSEN_VOICE: {
      return {
        ...state,
        choosenVoice: action.payload,
      }
    }

    case SET_MODAL_TYPE: {
      return {
        ...state,
        modalType: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
