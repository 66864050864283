import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Routes as AppRoutes } from './utils/constants'
import Loader from './components/Loader'
import NotFoundPage from './components/NotFoundPage'
import { translate } from 'react-i18next'

const Home = React.lazy(() => import('./components/Home'))
const About = React.lazy(() => import('./components/About'))
const Voices = React.lazy(() => import('./components/Voices'))
const Chonometer = React.lazy(() => import('./components/Chonometer'))
const Contacts = React.lazy(() => import('./components/Contacts'))
const Showreels = React.lazy(() => import('./components/Work'))
const VoiceDetails = React.lazy(() => import('./components/VoiceDetails'))
const Payments = React.lazy(() => import('./components/Payments'))
const ServicesPage = React.lazy(() => import('./components/ServicesPage'))
const ServicesTypePage = React.lazy(() => import('./components/ServicesTypePage'))
const ServiceDetail = React.lazy(() => import('./components/ServiceDetail'))
const Portfolio = React.lazy(() => import('./components/Portfolio'))
const Cases = React.lazy(() => import('./components/Cases'))
const Prices = React.lazy(() => import('./components/Pricing'))
const AllLanguages = React.lazy(() => import('./components/Home/HomeAllLanguages'))
const Blog = React.lazy(() => import('./components/Home/HomeBlog'))
const BlogNews = React.lazy(() => import('./components/Home/HomeBlog/BlogNews'))

const routes = [
  { component: Home, route: AppRoutes.MAIN },
  { component: About, route: AppRoutes.ABOUT },
  { component: ServicesPage, route: AppRoutes.SERVICES },
	{ component: ServicesTypePage, route: AppRoutes.SERVICES_TYPE },
  { component: ServiceDetail, route: AppRoutes.SERVICE_DETAIL },
  { component: Voices, route: AppRoutes.VOICES },
  { component: VoiceDetails, route: AppRoutes.VOICES_DETAIL },
  { component: Portfolio, route: AppRoutes.WORK },
  { component: Cases, route: AppRoutes.CASES },
  { component: Showreels, route: AppRoutes.SHOWREELS },
  { component: Payments, route: AppRoutes.PAYMENTS },
  { component: Chonometer, route: AppRoutes.CHRONOMETER },
  { component: Contacts, route: AppRoutes.CONTACTS },
  { component: Prices, route: AppRoutes.PRICING },
  { component: AllLanguages, route: AppRoutes.ALL_LANGUAGES },
  { component: Blog, route: AppRoutes.BLOG },
  { component: BlogNews, route: AppRoutes.BLOG_NEWS },
]

const Routes = ({ match, lng }) => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {lng !== 'en'
          ? routes.map(el => (
              <Route
                key={`${el.route}`}
                exact
                path={`${match.url}${el.route}`}
                component={el.component}
                status={el.status ? el.status : 200 }
              />
            ))
          : routes.map(el => (
              <Route key={`${el.route}`} exact path={el.route} component={el.component} status={el.status ? el.status : 200 } />
            ))}
            <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  )
}
export default translate('translations')(Routes)
