import { TOGGLE_FAVORITE_VOICE } from './types'

const DEFAULT_STATE = []

export function wishlist(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case TOGGLE_FAVORITE_VOICE: {
      let newState = [...state]
      const voice = action.payload

      // Remove product if present...
      newState = state.filter(el => el.id !== voice.id)

      // If items length is equal between new and old state
      // we should add a new element

      if (newState.length === state.length) {
        newState.push(voice)
      }
      return newState
    }
    default: {
      return state
    }
  }
}
