import { SET_VOICES_DESCR } from './types'

const DEFAULT_STATE = {}

export function voiceDescriptions(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_VOICES_DESCR: {
      return { ...action.payload }
    }

    default: {
      return state
    }
  }
}
