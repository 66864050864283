import { SET_CONTACTS, SET_STATUS_MESSAGE, SET_LODING_STATUS, CLEAR_LODING_STATUS } from './types'
import contactsApi from '../../services/contacts.service'

export const setContacts = payload => {
  return { type: SET_CONTACTS, payload }
}

export const setStatusMessage = payload => {
  return { type: SET_STATUS_MESSAGE, payload }
}

export const setContactLoadingStatus = () => {
  return { type: SET_LODING_STATUS }
}

export const clearContactLoadingStatus = () => {
  return { type: CLEAR_LODING_STATUS }
}

export const getContacts = () => {
  return dispatch => {
    return contactsApi
      .getContacts()
      .then(data => {
        dispatch(setContacts(data.data))
      })

      .catch(err => {
        console.error(err)
      })
  }
}

export const createFeedBack = data => {
  return dispatch => {
    dispatch(setContactLoadingStatus())
    return contactsApi
      .createFeedBack(data)
      .then(data => {
        dispatch(clearContactLoadingStatus())
        if (data.status === 200) {
          dispatch(setStatusMessage('Your message was sent'))
        } else {
          dispatch(setStatusMessage('Something went wrong'))
        }
      })

      .catch(err => {
        console.error(err)
      })
  }
}
