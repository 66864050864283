import React, { Component, Fragment } from 'react'
import Drawer from 'antd/lib/drawer'
import Navigation from '../Navigation'
import ButtonMenu from '../ButtonMenu'
import ButtonClose from '../ButtonClose'
import Logo from '../Logo'
import './index.sass'
import { NavLink } from 'react-router-dom'
import { Routes as AppRoutes } from '../../utils/constants'

class DrawerNavigation extends Component {
  state = { visible: false }

  onHandleClick = () => {
    this.setState({
      visible: !this.state.visible,
    })
  }

  render() {
    const { visible } = this.state
    const {lng} = this.props
    return (
      <Fragment>
        <header className="header-nav">
          <NavLink to={`${lng === 'en' ? `${AppRoutes.MAIN}` : `/${lng}`}`} className='logo'>
            <Logo />
          </NavLink>
          <ButtonMenu onHandleClick={this.onHandleClick} />
        </header>

        <Drawer
          title={
            <header className="header-nav">
              <NavLink to={`${lng === 'en' ? `${AppRoutes.MAIN}` : `/${lng}`}`} className='logo'>
                <Logo />
              </NavLink>
              <ButtonClose onHandleClick={this.onHandleClick} />
            </header>
          }
          placement={'top'}
          closable={false}
          onClose={this.onHandleClick}
          visible={visible}
          className="drawer"
          height="auto"
          forceRender
        >
          <Navigation onHandleClick={this.onHandleClick} />
        </Drawer>
      </Fragment>
    )
  }
}

export default DrawerNavigation
