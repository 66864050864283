import {
  LOADED,
  LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  CHANGE_MODAL_VISIBILITY,
  SET_CHOOSEN_VOICE,
  SET_MODAL_TYPE,
} from './types'

export const setloadingStatus = () => {
  return { type: LOADING }
}

export const clearloadingStatus = () => {
  return { type: LOADED }
}

export const setError = payload => {
  return { type: SET_ERROR, payload }
}

export const clearError = payload => {
  return { type: CLEAR_ERROR, payload }
}

export const changeModalVisibility = visibility => {
  return { type: CHANGE_MODAL_VISIBILITY, payload: visibility }
}

export const setChoosenVoice = payload => {
  return { type: SET_CHOOSEN_VOICE, payload }
}

export const setModalType = type => {
  return { type: SET_MODAL_TYPE, payload: type }
}
