import { bindActionCreators } from 'redux'
import * as actions from '../store/actions'

/**
 * Maps Redux's actions to props.
 * @param {function} dispatch Dispatch function, passed by Redux's connect().
 * @returns {object} Object with mapped actions.
 */
export const mapDispatchToProps = () => {
  return dispatch => {
    return { actions: bindActionCreators(actions, dispatch), dispatch }
  }
}
