import { SET_VOICES } from './types'

const DEFAULT_STATE = []

export function voices(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_VOICES: {
      return action.payload
		}

    default: {
      return state
    }
  }
}
