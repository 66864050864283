import React from 'react'
import { translate } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import './index.sass'

const NotFoundPage = props => {
    const {t, lng} = props
    return (
        <div className="notFoundPageContainer">
            <h2 className="notFoundPageTitle">
                  404
            </h2>
            <h2 className="notFoundPageTitle">
                {t('404')}
            </h2>
            <NavLink to={`${lng === 'en' ? '' : `/${lng}`}`}>
                <div className="notFoundPageToMainButton">
                    {t('toMainPage')}
                </div>
            </NavLink>

        </div>
    )
}

export default translate('translations')(NotFoundPage)