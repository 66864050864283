import { SET_ABOUT } from './types'

const DEFAULT_STATE = {}

export function about(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_ABOUT: {
      return action.payload
    }
    default: {
      return state
    }
  }
}
