import axios from 'axios'
import { apiSettings } from '../utils/constants'

class ContactsApi {
  getContacts = () => {
    return axios.get(apiSettings.contactsUrl)
  }

  createFeedBack = data => {
    return axios.post(apiSettings.createFeedBackUrl, data)
  }
}

const contactsApi = new ContactsApi()

export default contactsApi
