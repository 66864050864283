/**
 * Returns true if device is 'Small device'.
 * @returns {boolean} Whether device is smallDisplay or not.
 */

export const isDisplayView = () => {
  return window.screen.availWidth <= 1365
}

export const isSmallDisplayView = () => {
  return window.screen.availWidth <= 1023
}

export const isTabletView = () => {
  return window.screen.availWidth <= 767
}

export const isMoreThenDisplayView = () => {
  return window.screen.availWidth >= 1366
}
