export const firebaseConfig = {
  projectId: 'ummediaby-cc4fc',
  databaseURL: 'https://ummediaby-cc4fc.firebaseio.com',
  storageBucket: 'ummediaby-cc4fc.appspot.com',
  locationId: 'europe-west',
  apiKey: 'AIzaSyD5HU6dGWxCXiLDWSW_SvbHrdfec7vX924',
  authDomain: 'ummediaby-cc4fc.firebaseapp.com',
  messagingSenderId: '676933619519',
  measurementId: 'G-GBW1E43MLE',
  appId: '1:676933619519:web:1aead4044bc85c6772cd8a',
}
