class Storage {
  setData = (key, data) => {
    localStorage.setItem(key, data)
  }

  removeData = key => {
    localStorage.removeItem(key)
  }

  getData = key => localStorage.getItem(key)
}

const storage = new Storage()

export default storage
